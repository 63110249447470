import React, { Component } from "react";
import './style.local.css';



export default
class Footer extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="footer_wrapper">
        <div className="container">
        </div>
      </div>
    );
  }
}
