import React, { Component } from "react";
import './style.local.css';



export default
class Section2 extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="section2_wrapper" id="whyus"
        // style={{ 
        //   background: "url('/favicon.ico') center rgba(255, 202, 0, 0.68)",
        //   color: '#fff',
        //   backgroundSize: 'cover'
        // }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="s2_img">
                <img src="discover_pic.jpg" className="" alt="Packaging" width="90%" />
              </div>
            </div>
            <div className="col-md-6 s2_right">
              <h1 className="dispaly-1">Pourquoi choisir Créal Corn</h1>
              <p className="section2_p">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              </p>
              <div className="row">
                <div className="col-md-2">
                  <span className="check_container">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="col-md-10 check_description">
                  <h5 className="">Production Bio</h5>
                  <p className="section2_p_detail">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <span className="check_container">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="col-md-10 check_description">
                  <h5 className="">Riche en vitamines</h5>
                  <p className="section2_p_detail">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <span className="check_container">
                    <i className="fa fa-check"></i>
                  </span>
                </div>
                <div className="col-md-10 check_description">
                  <h5 className="">Saveures variées</h5>
                  <p className="section2_p_detail">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
