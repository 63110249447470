import React, { Component } from "react";
import './style.local.css';



export default
class ContactUs extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="contact_us_wrapper" id="contactus">
        <div className="contact1">
          <div className="container-contact1">
            <div className="contact1-pic js-tilt" data-tilt>
              <img src="img-01.png" alt="IMG" />
            </div>

            <form className="contact1-form validate-form">
              <span className="contact1-form-title">
                Contatez-nous
              </span>

              <div className="wrap-input1 validate-input" data-validate = "Le nom est réquis">
                <input className="input1" type="text" name="name" placeholder="Nom" />
                <span className="shadow-input1"></span>
              </div>

              <div className="wrap-input1 validate-input" data-validate = "Un email valide est requis: ex@abc.xyz">
                <input className="input1" type="text" name="email" placeholder="Email" />
                <span className="shadow-input1"></span>
              </div>

              <div className="wrap-input1 validate-input" data-validate = "Sujet obligatoire">
                <input className="input1" type="text" name="subject" placeholder="Sujet" />
                <span className="shadow-input1"></span>
              </div>

              <div className="wrap-input1 validate-input" data-validate = "Message obligatoire">
                <textarea className="input1" name="message" placeholder="Message"></textarea>
                <span className="shadow-input1"></span>
              </div>

              <div className="container-contact1-form-btn">
                <button className="contact1-form-btn">
                  <span>
                    Envoyer
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    );
  }
}
