import React, { Component } from "react";
import './style.local.css';



export default
class Carousel extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="carousel_wrapper">
        <div className="row">
          <div className="col-md-12" style={{ padding: 0 }}>
              <div className="carousel slide" id="carousel-554496">
                  <ol className="carousel-indicators">
                      <li data-slide-to="0" data-target="#carousel-554496"> </li>
                      <li data-slide-to="1" data-target="#carousel-554496"> </li>
                      <li data-slide-to="2" data-target="#carousel-554496" className="active"> </li>
                  </ol>
                  <div className="carousel-inner">
                      <div className="carousel-item"> 
                        <img className="d-block w-100" alt="Carousel Bootstrap First" src="pictures/carousel/1.jpg" />
                        {/* <img className="d-block w-100" alt="Carousel Bootstrap First" src="https://images.unsplash.com/photo-1564986021826-50dd9b728a76?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80" /> */}
                          <div className="carousel-caption">
                              <h4 className="display-2"> Restaurant View </h4>
                              <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </p>
                          </div>
                      </div>
                      <div className="carousel-item"> 
                        <img className="d-block w-100" alt="Carousel Bootstrap Second" src="pictures/carousel/2.jpg" />
                        {/* <img className="d-block w-100" alt="Carousel Bootstrap Second" src="https://images.unsplash.com/photo-1432139523732-e9d8af332501?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1510&q=80" /> */}
                          <div className="carousel-caption">
                              <h4 className="display-2"> printing &amp; typesetting </h4>
                              <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </p>
                          </div>
                      </div>
                      <div className="carousel-item active"> 
                        <img className="d-block w-100" alt="Carousel Bootstrap Third" src="pictures/carousel/3.jpg" />
                        {/* <img className="d-block w-100" alt="Carousel Bootstrap Third" src="https://images.unsplash.com/photo-1566847025198-8f59cb7fcebd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80" /> */}
                          <div className="carousel-caption">
                              <h4 className="display-2"> printer took </h4>
                              <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </p>
                          </div>
                      </div>
                  </div> <a className="carousel-control-prev" href="#carousel-554496" data-slide="prev"><span className="carousel-control-prev-icon"></span> <span className="sr-only">Previous</span></a> <a className="carousel-control-next" href="#carousel-554496" data-slide="next"><span className="carousel-control-next-icon"></span> <span className="sr-only">Next</span></a>
              </div>
          </div>
      </div>
      </div>
    );
  }
}
