import React, { Component } from "react";
import './style.local.css';



export default
class Section1 extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="section1_wrapper" id="discover"
        // style={{ 
        //   background: "url('/favicon.ico') center rgba(255, 202, 0, 0.68)",
        //   color: '#fff',
        //   backgroundSize: 'cover'
        // }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 s1_right">
              <h1 className="dispaly-1">Découvrez Créal Corn</h1>
              <h3 className="" style={{ color: "#686868", textTransform: "uppercase" }}>Amour &amp; Partage</h3>
              <p className="section1_p">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.
              </p>
              <a href="#preorder" className="btn btn-lg btn-success">Commander</a>
            </div>
            <div className="col-md-6 text-center">
              <div className="s1_img">
                <img src="corn_combo.png" className="" alt="Packaging" width="90%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
