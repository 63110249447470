import React, { Component } from "react";
import Navbar from './Components/Navbar/index';
import Carousel from './Components/Carousel/index';
import Section1 from './Components/Section1'
import Section2 from './Components/Section2'

import './App.css';



export default
class App extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="whole_page">
        <div className="home_wrapper">
          <div className="container-fluid page_upper">
            <Carousel />
            <div className="upper_absolute">
              <Navbar />
            </div>
          </div>
        </div>

        <div className="section_page">
          <Section1 />
          <Section2 />
        </div>
      </div>
    );
  }
}
