import React, { Component } from "react";
import Footer from './Components/Footer/index';
import ContactUs from './Components/Contactus/index';

import './App.css';



export default
class App2 extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="whole_page_part2">
        <ContactUs />
        <Footer />
      </div>
    );
  }
}
