import React, { Component } from "react";
import './style.local.css';



export default
class Navbar extends Component {
  constructor() {
    super()
    this.state = {}
  }


  render() {

    return (
      <div className="nav_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <p className="navbar_p">Mon crush c'est Créal Corn!</p>
            </div>
            <div className="col-md-4">
              <p className="navbar_p">
                Suivez-nous sur 
                &nbsp;
                &nbsp;
                <span className="navbar_social"><i className="fab fa-facebook"></i></span>&nbsp;&nbsp;&nbsp;
                <span className="navbar_social"><i className="fab fa-twitter"></i></span>&nbsp;&nbsp;&nbsp;
                <span className="navbar_social"><i className="fab fa-instagram"></i></span>&nbsp;&nbsp;&nbsp;
                <span className="navbar_social"><i className="fab fa-pinterest"></i></span>
              </p>
            </div>
            <div className="col-md-4">
            <p className="navbar_p">
                <span className="navbar_phone"><i className="fa fa-phone"></i></span>&nbsp;
                &nbsp;
                (+237) 690 934 873
              </p>
            </div>
          </div>
        </div>
        <div className="navbar_start">
          <nav className="navbar navbar-expand-lg navbar-light" 
            style={{ 
              background: "url('pictures/pattern-bg.png') center rgba(255, 202, 0, 0.68)",
              color: '#fff',
              backgroundSize: 'cover'
             }}
          >
            <a className="navbar-brand" href="#" style={{ paddingLeft: 30 }}>
              <img className="brand_logo" alt="Logo picture" src="logo192.png" width="80px" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse top_navbar_collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="#">Accueil <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#discover">Découvrir</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#whyus">Pourquoi nous</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#ourroad">Notre parcours</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#aboutus">A propos de nous</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contactus">Contactez-nous</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}
